export const ACORN_AD_FILTERING_LOGIC_DISABLED = 'ACORN_AD_FILTERING_LOGIC_DISABLED' as const;
export const ADS_ITINERARY_AIRLINE_NAME_ENABLED = 'ADS_ITINERARY_AIRLINE_NAME_ENABLED' as const;
export const ADS_ITINERARY_COPY_CHANGES_ENABLED = 'ADS_ITINERARY_COPY_CHANGES_ENABLED' as const;
export const CAN_SEE_PACKAGES_WEBSITE = 'CAN_SEE_PACKAGES_WEBSITE' as const;
export const DISABLE_PRICE_ALERTS_AND_S2L_FOR_BAGGAGE_SEARCH =
  'DISABLE_PRICE_ALERTS_AND_S2L_FOR_BAGGAGE_SEARCH' as const;
export const ENABLE_ACORN_GTM_INTEGRATION = 'EnableAcornGtmIntegration' as const;
export const ENABLE_ACORN_SONAR_V3_CREATE_SESSION_REQUEST =
  'ENABLE_ACORN_SONAR_V3_CREATE_SESSION_REQUEST' as const;
export const ENABLE_ACORN_SONAR_V3_POLL_REQUEST = 'EnableAcornSonarV3PollRequest' as const;
export const ENABLE_AIRLINE_BADGE_ON_DAYVIEW = 'EnableAirlineBadgeOnDayview' as const;
export const ENABLE_BAGGAGE_FILTER_IN_SEARCH_RESULTS_DESKTOP =
  'ENABLE_BAGGAGE_FILTER_IN_SEARCH_RESULTS_DESKTOP' as const;
export const ENABLE_BANANA_GTM_INTEGRATION = 'ENABLE_BANANA_GTM_INTEGRATION' as const;
export const ENABLE_BOOKING_MULTI_CONFIDENCE_MESSAGING =
  'enable_booking_multi_confidence_messaging' as const;
export const ENABLE_BPK_CHIP_GROUP_FOR_FILTERS = 'ENABLE_BPK_CHIP_GROUP_FOR_FILTERS' as const;
export const ENABLE_BUG_FIX = 'ENABLE_BUG_FIX' as const;
export const ENABLE_CALENDAR_PRICES = 'ENABLE_CALENDAR_PRICES' as const;
export const ENABLE_CALENDAR_TYPE_IS_INITIAL_TRIP_TYPE =
  'ENABLE_CALENDAR_TYPE_IS_INITIAL_TRIP_TYPE' as const;
export const ENABLE_COMBINED_EXPLORE_ANYTIME_SEARCH =
  'ENABLE_COMBINED_EXPLORE_ANYTIME_SEARCH' as const;
export const ENABLE_COMBINED_EXPLORE_MONTH_SEARCH = 'ENABLE_COMBINED_EXPLORE_MONTH_SEARCH' as const;
export const ENABLE_CONSENT_MANAGEMENT = 'ENABLE_CONSENT_MANAGEMENT' as const;
export const ENABLE_DESTINATION_GUIDEBOOK = 'ENABLE_DESTINATION_GUIDEBOOK' as const;
export const ENABLE_DESTINATION_PREVIEW_POC = 'ENABLE_DESTINATION_PREVIEW_POC' as const;
export const ENABLE_DYNAMIC_HEADER_TABS_REDIRECT = 'ENABLE_DYNAMIC_HEADER_TABS_REDIRECT' as const;
export const ENABLE_ENFORCE_TRIP_TYPE = 'ENABLE_ENFORCE_TRIP_TYPE' as const;
export const ENABLE_FILTERED_DAY_VIEW = 'ENABLE_FILTERED_DAY_VIEW' as const;
export const ENABLE_FSC_GC = 'ENABLE_FSC_GC' as const;
export const ENABLE_GC_CAR_HIRE_RECOMMENDATIONS_IN_COMBINED_RESULTS =
  'ENABLE_GC_CAR_HIRE_RECOMMENDATIONS_IN_COMBINED_RESULTS' as const;
export const ENABLE_GC_HOTELS_RECOMMENDATIONS_IN_COMBINED_RESULTS =
  'ENABLE_GC_HOTELS_RECOMMENDATIONS_IN_COMBINED_RESULTS' as const;
export const ENABLE_GLOBAL_NAVIGATION = 'ENABLE_GLOBAL_NAVIGATION' as const;
export const ENABLE_GOOGLE_ONE_TAP = 'ENABLE_GOOGLE_ONE_TAP' as const;
export const ENABLE_GOOGLE_ONE_TAP_FEDCM = 'ENABLE_GOOGLE_ONE_TAP_FEDCM' as const;
export const ENABLE_GOOGLE_TAG_MANAGER = 'ENABLE_GOOGLE_TAG_MANAGER' as const;
export const ENABLE_GOOGLE_YOLO = 'ENABLE_GOOGLE_ONE_TAP' as const;
export const ENABLE_GRAPHIC_PROMO = 'ENABLE_GRAPHIC_PROMO' as const;
export const ENABLE_G_C_CAR_HIRE_RECOMMENDATIONS_IN_FLIGHTS_DAYVIEW_MWEB =
  'ENABLE_G_C_CAR_HIRE_RECOMMENDATIONS_IN_FLIGHTS_DAYVIEW_MWEB' as const;
export const ENABLE_G_C_HOTELS_RECOMMENDATIONS_IN_FLIGHTS_DAYVIEW_MWEB =
  'ENABLE_G_C_HOTELS_RECOMMENDATIONS_IN_FLIGHTS_DAYVIEW_MWEB' as const;
export const ENABLE_HEADER_TABS_IN_COMBINED_RESULTS =
  'ENABLE_HEADER_TABS_IN_COMBINED_RESULTS' as const;
export const ENABLE_HEADER_TABS_REDIRECT_TO_NEW_BROWSER_TAB =
  'ENABLE_HEADER_TABS_REDIRECT_TO_NEW_BROWSER_TAB' as const;
export const ENABLE_HOMEPAGE_HERO = 'ENABLE_HOMEPAGE_HERO' as const;
export const ENABLE_HOMEPAGE_HERO_VIDEO = 'ENABLE_HOMEPAGE_HERO_VIDEO' as const;
export const ENABLE_INLINE_HOTELS_ADS = 'ENABLE_INLINE_HOTELS_ADS' as const;
export const ENABLE_INTERNAL_LINKING = 'ENABLE_INTERNAL_LINKING' as const;
export const ENABLE_INTERNATIONAL_SITES = 'ENABLE_INTERNATIONAL_SITES' as const;
export const ENABLE_INVALID_JSON_RESPONSE_HANDLING =
  'ENABLE_INVALID_JSON_RESPONSE_HANDLING' as const;
export const ENABLE_LOGIN_FORM_GC_IN_BANANA = 'ENABLE_LOGIN_FORM_GC_IN_BANANA' as const;
export const ENABLE_LOGIN_FORM_GC_IN_PAS_BANANA = 'ENABLE_LOGIN_FORM_GC_IN_PAS_BANANA' as const;
export const ENABLE_MOROCCO_RAMADAN_BANNER = 'ENABLE_MOROCCO_RAMADAN_BANNER' as const;
export const ENABLE_MULTI_CITY_QUICK_LINK = 'ENABLE_MULTI_CITY_QUICK_LINK' as const;
export const ENABLE_NEW_TRANSLATIONS_XSELL__B_P = 'ENABLE_NEW_TRANSLATIONS_XSELL__B_P' as const;
export const ENABLE_NEW_TRANSLATIONS_XSELL__B_P_MWEB =
  'ENABLE_NEW_TRANSLATIONS_XSELL__B_P_MWEB' as const;
export const ENABLE_PERIMETER_X_FOR_BANANA = 'ENABLE_PERIMETER_X_FOR_BANANA' as const;
export const ENABLE_PQS_SURVEY_STAR_RATING = 'ENABLE_PQS_SURVEY_STAR_RATING' as const;
export const ENABLE_PRERENDER = 'ENABLE_PRERENDER' as const;
export const ENABLE_PRICE_CALENDAR_DATA = 'ENABLE_PRICE_CALENDAR_DATA' as const;
export const ENABLE_PRICE_REFRESH = 'ENABLE_PRICE_REFRESH' as const;
export const ENABLE_PSS_UI_REFRESH = 'ENABLE_PSS_UI_REFRESH' as const;
export const ENABLE_RAIL = 'ENABLE_RAIL' as const;
export const ENABLE_READ_BEFORE_BOOKING_BOTTOM_SHEET =
  'ENABLE_READ_BEFORE_BOOKING_BOTTOM_SHEET' as const;
export const ENABLE_RELEVANT_DIGITAL_AS_PREBID_PROVIDER =
  'ENABLE_RELEVANT_DIGITAL_AS_PREBID_PROVIDER' as const;
export const ENABLE_RUSSIA_BANNER = 'ENABLE_RUSSIA_BANNER' as const;
export const ENABLE_RUSSIA_BANNER_ACORN = 'EnableRussiaBannerAcorn' as const;
export const ENABLE_SAVE_TO_LIST = 'ENABLE_SAVE_TO_LIST' as const;
export const ENABLE_SHARED_BAGGAGE_ICONS = 'ENABLE_SHARED_BAGGAGE_ICONS' as const;
export const ENABLE_SHARED_TCS_FILTER = 'ENABLE_SHARED_TCS_FILTER' as const;
export const ENABLE_SINGLE_BUILD = 'ENABLE_SINGLE_BUILD' as const;
export const ENABLE_SINGLE_ROUTER = 'ENABLE_SINGLE_ROUTER' as const;
export const ENABLE_SONAR_ALPHA = 'ENABLE_SONAR_ALPHA' as const;
export const ENABLE_SPONSORED_BOOKING_PANEL_ADVERT =
  'ENABLE_SPONSORED_BOOKING_PANEL_ADVERT' as const;
export const ENABLE_SPONSORED_DESTINATION_DAY_VIEW =
  'ENABLE_SPONSORED_DESTINATION_DAY_VIEW' as const;
export const ENABLE_STREVDA_ADS_AFTER_GUIDEBOOK = 'ENABLE_STREVDA_ADS_AFTER_GUIDEBOOK' as const;
export const ENABLE_TCS = 'EnableTcs' as const;
export const ENABLE_TOTAL_COST = 'ENABLE_TOTAL_COST' as const;
export const ENABLE_TOTAL_COST_BAGGAGE_FILTERS = 'ENABLE_TOTAL_COST_BAGGAGE_FILTERS' as const;
export const ENABLE_TOTAL_COST_SONAR_V3 = 'ENABLE_TOTAL_COST_SONAR_V3' as const;
export const ENABLE_UPSELLS_EXPANDABLE_PRICING_OPTION_ACORN =
  'ENABLE_UPSELLS_EXPANDABLE_PRICING_OPTION_ACORN' as const;
export const ENABLE_US_BELARUS_BANNER = 'ENABLE_US_BELARUS_BANNER' as const;
export const ENABLE_US_BELARUS_BANNER_ACORN = 'EnableUSBelarusBannerAcorn' as const;
export const ENABLE_WEB_TO_APP_BANNERS = 'ENABLE_WEB_TO_APP_BANNERS' as const;
export const ENABLE_WEB_TO_APP_BANNERS_BANANA = 'ENABLE_WEB_TO_APP_BANNERS_BANANA' as const;
export const FPS_DEGRADATION_SLOW_DOWN_POLLING = 'FPS_DEGRADATION_SLOW_DOWN_POLLING' as const;
export const HOTEL_REDIRECT_PRICE_CONSISTENCY = 'HOTEL_REDIRECT_PRICE_CONSISTENCY' as const;
export const IS_INTERNAL_TRAFFIC = 'IS_INTERNAL_TRAFFIC' as const;
export const NATIVE_BOTTOM_CAR_HIRE_XSELL = 'native_bottom_car_hire_xsell' as const;
export const NATIVE_BOTTOM_HOTELS_XSELL = 'native_bottom_hotels_xsell' as const;
export const PRE_KONDO_BOOKING_PANEL_LOADING = 'PRE_KONDO_BOOKING_PANEL_LOADING' as const;
export const RENDER_SPONSORED_BOOKING_PANEL_ADVERT =
  'RENDER_SPONSORED_BOOKING_PANEL_ADVERT' as const;
export const SHOULD_AVOID_BANANA_RESULTS = 'SHOULD_AVOID_BANANA_RESULTS' as const;
export const SHOW_AIRLINE_TAG = 'SHOW_AIRLINE_TAG' as const;
export const SHOW_COMBINED_RESULTS_BOTTOM_GOOGLE_ADVERT =
  'SHOW_COMBINED_RESULTS_BOTTOM_GOOGLE_ADVERT' as const;
export const SHOW_COMBINED_RESULTS_CAR_HIRE = 'SHOW_COMBINED_RESULTS_CAR_HIRE' as const;
export const SHOW_COMBINED_RESULTS_HOTELS = 'SHOW_COMBINED_RESULTS_HOTELS' as const;
export const SHOW_DEBUG_CONTROLS = 'SHOW_DEBUG_CONTROLS' as const;
export const SHOW_ECO_FILTER = 'SHOW_ECO_FILTER' as const;
export const SHOW_GOOGLE_INLINE_ADS = 'SHOW_GOOGLE_INLINE_ADS' as const;
export const SHOW_INLINE_ADS = 'SHOW_INLINE_ADS' as const;
export const SHOW_PACKAGES_BANNER = 'SHOW_PACKAGES_BANNER' as const;
export const SHOW_PARTNER_MESSAGES = 'SHOW_PARTNER_MESSAGES' as const;
export const SHOW_PQS_MODAL = 'SHOW_PQS_MODAL' as const;
export const SHOW_PQS_NUMERICAL_RATING = 'show_pqs_numerical_rating' as const;
export const SHOW_PQS_SCORE = 'SHOW_PQS_SCORE' as const;
export const SHOW_PQS_SURVEY_ON_ACORN = 'SHOW_PQS_SURVEY_ON_ACORN' as const;
export const SHOW_UNPRICED_OPTION = 'SHOW_UNPRICED_OPTION' as const;
export const TRACK_FLIGHT_ITINERARY_PRICING_OPTIONS =
  'TRACK_FLIGHT_ITINERARY_PRICING_OPTIONS' as const;
export const USE_ACCOMMODATION_RESULTS_IN_SAM_NATIVE_BOTTOM_HOTELS_XSELL =
  'use_accommodation_results_in_sam_bottom_hotels_xsell' as const;
export const USE_LOGIN_FORM_GC_IN_HEADER = 'use_login_form_gc_in_header' as const;
export const USE_PREPRODPARTNER_MESSAGE_CONFIG_ACORN =
  'use_preprodpartner_message_config_acorn' as const;
export const USE_PREPRODPARTNER_MESSAGE_CONFIG_BANANA =
  'USE_PREPRODPARTNER_MESSAGE_CONFIG_BANANA' as const;
export const VALIDATE_FQS_TABS_PRICE = 'VALIDATE_FQS_TABS_PRICE' as const;
export const XSELL_BOOKING_PANEL_LOADING = 'xsell_booking_panel_loading' as const;
